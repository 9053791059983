import React from 'react';

export default function IntroVideo({ teacherRef }) {
  return (
    <div id="IntroVideo" ref={teacherRef}>
      <div className="container">
        <div className="row">
          <div className="col-5">
            <video width="100%" height="650px" controls>
              <source src="./videos/intro.mp4" type="video/mp4" />
            </video>
          </div>
          <div className="col-4">
            <div className="card">
              <div className="circle">
                <img src="./images/intro2.JPG" />
              </div>
              <div>
                <h3>“Sam Sebe Browist” kursi</h3>
                <ul>
                  <li>✔ Formalar va yuz proporsiyalari.</li>
                  <li>✔ Qosh turlari.</li>
                  <li>✔ Qosh tahlili.</li>
                  <li>✔ Qosh shakli bilan yuz kamchiliklarini bartaraf etish.</li>
                  <li>✔ Mahsulotlar obzo’ri.</li>
                  <li>✔ Mahsulotlar tahlili. </li>
                  <li>✔ O’z o’zida sinab ko’rish imkoniyati.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
