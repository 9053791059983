import React, { useEffect } from 'react';
import Button from '../Button';
import Video from './Video';
import { selectIsAuth } from '../../redux/userStateSlice';
import { showSignIn } from '../../redux/appStateSlice';
import { useDispatch, useSelector } from 'react-redux';
import { showPrice, fetchCourses } from '../../redux/courseStateSlice';
import { useParams } from 'react-router';
import ReactLoading from 'react-loading';
import { Link } from 'react-router-dom';
import Info from './Info';
import Content from './Content';
export default function Courseover() {
  const userIsAuth = useSelector(selectIsAuth);
  const dispatch = useDispatch();
  const params = useParams();
  const selectCourse = useSelector((state) => state.course.courses);
  const isCourseLoading = selectCourse.status === 'loading';

  const buyCourse = () => {
    userIsAuth ? dispatch(showPrice()) : dispatch(showSignIn());
  };

  const getCourse = async () => {
    const data = await dispatch(fetchCourses());
  };
  React.useEffect(() => {
    window.scrollTo(0, 0);
    getCourse();
  }, []);

  if (isCourseLoading) {
    return (
      <ReactLoading className="loading" type="balls" color="#41ae8d" height={100} width={100} />
    );
  }

  return (
    <div id="Courseoverview">
      <div className="layer">
        <div className="container">
          {selectCourse.items.map((item) => {
            return (
              <div className="main">
                <h1>Assalomu alekum!</h1>
                <h1>Bizni tanlaganingizdan xursandmiz!</h1>
                <div className="link">
                  <Link className="btn" to={'/course/' + item._id}>
                    Asosiy darsga o'tish
                  </Link>
                </div>
                <div className="row" key={item._id}>
                  <div className="row">
                    <Content />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
