import React from 'react';
import Modal from 'react-modal';
import '../style/main.scss';
import { useSelector, useDispatch } from 'react-redux';
import { closeAuthForm, selectAuthModal, showSignUp } from '../../redux/appStateSlice';
import { MdClear } from 'react-icons/md';
import { signIn } from '../../APIs/auth';
import { fetchAuth, logout } from '../../redux/userStateSlice';
import toast, { Toaster } from 'react-hot-toast';

const customStyles = {
  content: {
    top: '15%',
    left: '37.5%',
    right: 'auto',
    bottom: 'auto',
    backgroundColor: 'white',
    width: '30%',
    maxHeight: '100vh',
    overflowY: 'auto',
    borderRadius: '16px',
    padding: ' 24px',
    paddingTop: '30px',
    paddingButtom: '30px',
    animationName: 'modalFade',
    animationDuration: '0.5s',
    animationTimingFunction: 'linear',
  },
  '@media (max-width: 1050px)': {
    width: '100%',
  },
};
export default function Logout() {
  const selectModal = useSelector(selectAuthModal);
  const dispatch = useDispatch();

  const handleLogout = (event) => {
    switch (event) {
      case 'yes':
        dispatch(logout());
        localStorage.removeItem('token');
        dispatch(closeAuthForm());
        break;
      case 'no':
        dispatch(closeAuthForm());
        break;
    }
  };
  return (
    <div className="modal">
      <Modal
        ariaHideApp={false}
        style={customStyles}
        isOpen={selectModal === 'logout' ? true : false}>
        <div className="row" style={{ justifyContent: 'flex-end', fontWeight: 'bold' }}>
          <div style={{ cursor: 'pointer' }} onClick={() => dispatch(closeAuthForm())}>
            <MdClear />
          </div>
        </div>

        <div className="logout">
          <h2>Chiqish</h2>

          <p>Aniq chiqmoqchimisiz ?</p>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
            <div
              style={{ backgroundColor: 'red' }}
              onClick={() => handleLogout('yes')}
              className="buttonLogout">
              Xa
            </div>
            <button
              style={{ minWidth: 100 }}
              className="buttonLogout"
              onClick={() => handleLogout('no')}>
              Yo`q, qolaman
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
