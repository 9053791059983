import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { showSignIn } from '../redux/appStateSlice';
import { showPrice } from '../redux/courseStateSlice';
import { selectIsAuth, selectUser } from '../redux/userStateSlice';

export default function Button(props) {
  const profile = useSelector(selectUser);
  const userIsAuth = useSelector(selectIsAuth);
  const dispatch = useDispatch();
  console.log('profile', profile);
  const buyCourse = () => {
    userIsAuth ? dispatch(showPrice()) : dispatch(showSignIn());
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '16px',
      }}>
      {profile?.isPayed ? (
        <Link to="/courseoverview" className="btn">
          Kursni davom ettirish
        </Link>
      ) : (
        <button style={{ border: 'none' }} className="btn" onClick={() => buyCourse()}>
          Sotib olish
        </button>
      )}
    </div>
  );
}
