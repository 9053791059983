import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import instance from '../APIs/axios';

export const fetchCourses = createAsyncThunk('posts/fetchCourses', async () => {
  const { data } = await instance.get('/course');
  // console.log('fetchCourses', data);
  return data;
});

export const fetchCourseID = createAsyncThunk('posts/fetchCourseByID', async (id) => {
  // console.log('posts/fetchCourseByID id', id);
  const { data } = await instance.get(`/course/${id}`);

  return data;
});

export const fetchLessonByCourseID = createAsyncThunk('posts/fetchLessonByCourseID', async (id) => {
  // console.log('posts/fetchLessonByCourseID id', id);
  const { data } = await instance.get(`lessons/byCourseID/${id}`);
  return data;
});
// export const fetchLesson = createAsyncThunk('post/fetchLesson', async () => {
//   await instance.get('/lessons');
//   console.log('fetchLesson', fetchLesson);
// });

const coursesSlice = createSlice({
  name: 'course',
  initialState: {
    courses: {
      total: null,
      items: [],
      status: 'loading',
    },
    pricemodal: false,
    videoLink: null,
    exercisesID: null,
    lessonID: null,
    courseByID: {
      items: null,
      units: [],
      lessons: [],
      status: 'loading',
    },
    lessons: {
      total: null,
      items: [],
      status: 'loading',
    },
  },
  reducers: {
    setVideoLink: (state, action) => {
      // console.log('setVideoLink', action.payload);
      state.videoLink = action.payload.videoLink;
      state.lessonID = action.payload.lessonID;
      localStorage.setItem('lastvideolink', action.payload.videoLink);
      localStorage.setItem('lastlessonID', action.payload.lessonID);
    },
    showPrice: (state) => {
      state.pricemodal = true;
    },
    closePrice: (state) => {
      state.pricemodal = false;
    },
  },
  extraReducers: {
    // get courses
    [fetchCourses.pending]: (state) => {
      state.courses.status = 'loading';
    },
    [fetchCourses.fulfilled]: (state, action) => {
      state.courses.items = action.payload.data;
      state.courses.total = action.payload.total;
      state.courses.status = 'loaded';
    },
    [fetchCourses.rejected]: (state) => {
      state.courses.items = [];
      state.courses.status = 'error';
    },
    // get Lessons
    // [fetchLesson.pending]: (state) => {
    //   state.lessons.status = 'loading';
    // },
    // [fetchLesson.fulfilled]: (state, action) => {
    //   state.lessons.items = action.payload.data;
    //   state.lessons.total = action.payload.total;
    //   state.lessons.status = 'loaded';
    // },
    // [fetchLesson.rejected]: (state) => {
    //   state.lessons.items = [];
    //   state.lessons.status = 'error';
    // },

    // get course by id
    [fetchCourseID.pending]: (state) => {
      state.courseByID.status = 'loading';
    },
    [fetchCourseID.fulfilled]: (state, action) => {
      state.courseByID.items = action.payload.data;
      state.courseByID.status = 'loaded';
    },
    [fetchCourseID.rejected]: (state) => {
      state.courseByID.items = null;
      state.courseByID.status = 'error';
    },

    // get lessons by corse id
    [fetchLessonByCourseID.pending]: (state) => {
      state.courseByID.status = 'loading';
    },
    [fetchLessonByCourseID.fulfilled]: (state, action) => {
      // console.log('fetchLesonByUnitID fulfilled', action.payload.data);
      state.courseByID.lessons = action.payload.data;
      state.courseByID.status = 'loaded';
    },
    [fetchLessonByCourseID.rejected]: (state) => {
      state.courseByID.lessons = [];
      state.courseByID.status = 'error';
    },
  },
});
export const { setVideoLink, showPrice, closePrice } = coursesSlice.actions;

export const selectCourses = (state) => state.course.courses;
export const selectPriceModal = (state) => state.course.pricemodal;
export const selectLessonID = (state) => state.course.lessonID;
export const selectLessons = (state) => state.course.lessons;
export const coursesReducer = coursesSlice.reducer;
