import React from 'react';

export default function Privacy() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div id="Privacy">
      <div className="container">
        <h1>Privacy</h1>
      </div>
    </div>
  );
}
