import React from 'react';
import { useSelector } from 'react-redux';
import { selectLessonID } from '../../redux/courseStateSlice';

export default function DefinationLesson({ lessons }) {
  const selectLessonId = useSelector(selectLessonID);
  console.log('selectId lessons', selectLessonId);
  return (
    <>
      {lessons?.map((item) => {
        return (
          <div key={item._id}>
            {selectLessonId == item._id ? (
              <>
                <h3>{item.title}</h3>
                <p>{item.description}</p>
              </>
            ) : null}
          </div>
        );
      })}
    </>
  );
}
