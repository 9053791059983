import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { showSignIn, showSignUp, showLogout, closeAuthForm } from '../redux/appStateSlice';
import { MdViewHeadline, MdClear } from 'react-icons/md';
import { selectIsAuth, selectUser } from '../redux/userStateSlice';
import { IoIosLogOut } from 'react-icons/io';

export default function Navbar() {
  const userIsAuth = useSelector(selectIsAuth);
  console.log('userisAuth', userIsAuth);
  const profile = useSelector(selectUser);
  console.log('profile', profile);
  const dispatch = useDispatch();
  const [style, setStyle] = useState(false);

  function openNavbar() {
    setStyle(!style);
    dispatch(closeAuthForm());
  }
  function closeNavbar() {
    setStyle(false);
  }
  function handleShowSignIn() {
    dispatch(showSignIn());

    closeNavbar();
  }
  function handleShowSignUp() {
    dispatch(showSignUp());
    closeNavbar();
  }

  return (
    <div id="Navbar">
      <div className={style ? 'responsive' : null}>
        <div className="container">
          <div className="row">
            <div className="navbarBrand">
              <div className="brand">
                <Link to="/">Medinah</Link>
              </div>
            </div>
            <div className="nav">
              {userIsAuth ? (
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  {profile.full_name}
                  <div
                    onClick={() => dispatch(showLogout())}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginLeft: 5,
                      backgroundColor: '#ffe4e4',
                      padding: 5,
                      borderRadius: 7,
                      justifyContent: 'center',
                      cursor: 'pointer',
                    }}>
                    <IoIosLogOut onClick={() => closeNavbar()} size="20" color="red" />
                  </div>
                </div>
              ) : (
                <div className="sign">
                  <div onClick={() => handleShowSignIn()}>Kirish</div>
                  <span> | </span>
                  <div onClick={() => handleShowSignUp()}>Ro'yxatdan o'tish</div>
                </div>
              )}
            </div>
            <div className="burger" onClick={() => openNavbar()}>
              {style ? <MdClear className="icon" /> : <MdViewHeadline className="icon" />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
