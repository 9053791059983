import React from 'react';
import { Link } from 'react-router-dom';
import Button from './Button';
import { MdOutlineCheck } from 'react-icons/md';
import { selectIsAuth } from '../redux/userStateSlice';
import { showSignIn } from '../redux/appStateSlice';
import { useDispatch, useSelector } from 'react-redux/es/exports';
import { fetchCourses, showPrice } from '../redux/courseStateSlice';
const List = [
  {
    name: 'Intensiv',
    title: '3 oylik kurs',
    info: [
      {
        k: 'Kursga 3 oylik kirish huquqi',
      },
      {
        k: 'Umumiy chatga kirish',
      },
      {
        k: 'Vazifalar tekshirilishi',
      },
      {
        k: 'Medinah Habibiyaga individual savollar',
      },
      {
        k: '  Mahsulotlar optom joylari bilan tanishtirish',
      },
      {
        k: '  Medinah Habibiyadan online seminar',
      },
      {
        k: "  Kurs tugatganligi to'g'risida sertifikat beriladi",
      },
    ],
    price: "Oyiga 349 000 so'm",
    commonPrice: '1 047 000',
    calcPrice: "847 000 so'm",
  },
  {
    name: 'Standart',
    title: '3 oylik kurs',
    price: "Oyiga 339 000 so'm",
    info: [
      {
        k: 'Kursga 1 oylik kirish huquqi',
      },
      {
        k: 'Umumiy chatga kirish',
      },
      {
        k: 'Vazifalar tekshirilishi',
      },

      {
        k: 'Individual savollarga javob',
      },
      {
        k: 'Mahsulotlar optom joylari bilan tanishtirish',
      },
    ],
    price: "249 000 so'm",
    commonPrice: '747 000',
    calcPrice: "547 000 so'm",
  },
];

export default function Course(props) {
  const userIsAuth = useSelector(selectIsAuth);
  const dispatch = useDispatch();
  const buyCourse = () => {
    userIsAuth ? dispatch(showPrice()) : dispatch(showSignIn());
  };

  return (
    <div id="Tarif" ref={props.tarifRef}>
      <div className="container">
        <h1>“Sam Sebe Browist” kursining narxi</h1>
        <p>O'zingizga mos keladigan o'quv formatini tanlang va hoziroq ro'yxatdan o'ting!</p>
        <div className="row">
          {List.map((item, index) => {
            return (
              <div className="col-5" key={index}>
                <div
                  className="card"
                  style={{
                    backgroundImage: `url('./images/bg1.png')`,
                    backgroundSize: 'cover',
                    backroundRepeat: 'no-repeat',
                  }}>
                  <div className="cardHeader">
                    <h1>{item.name}</h1>
                    {
                      <>
                        <h3>{item.price}</h3>
                      </>
                    }
                  </div>
                  <div className="cardBody">
                    <ul>
                      {item.info.map((i, j) => (
                        <li key={j}>
                          <MdOutlineCheck style={{ marginRight: '16px' }} />
                          {i.k}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="cardFooter">
                    <Button />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
