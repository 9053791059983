import React, { useState, useEffect } from 'react';

export default function Feature() {
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 800;

  useEffect(() => {
    window.addEventListener('resize', () => setWidth(window.innerWidth));
  }, []);
  return (
    <div id="Feature">
      <div className="container">
        <h1>Kurs kimlar uchun?</h1>
        <div className="row">
          <div className="col-5">
            <div>
              <h3> Professional lamimayker bo'lishni xohlaysizmi? </h3>
              <p>
                Avvalo o’z o’ziga master bo’lish xohishi bo’lgan ayol va qizlar uchun! Hamda
                professional lamimaykerlik kasbini tanlash arafasidagilar uchun ayni muddao! Agarda
                siz lamimaykerlik, browistlik, qosh arxitekturasi, qoshni termasdan halol metodda
                formaga solishni o’rganmoqchi, va avvalo buni o’zingizda amalda qo’llamoqchi
                bo’lsangiz, kursimiz siz uchun ideal!
              </p>
            </div>
          </div>
          <div className="col-4">
            <div className="circle">
              <img src="./images/6.JPG" />
            </div>
          </div>
        </div>
        <div className="row">
          {width < breakpoint ? (
            <>
              <div className="col-5">
                <div>
                  <h3>Lamimaykerlik sohasida ishlashni xohlaysizmi? </h3>
                  <p>
                    Bu kursni muvaffaqiyatli yakunlab, ushbu sohada ishlashingiz va yaxshi daromadga
                    ega bo’lishingiz ham mumkin. Biz sizlarga qoshlarni xina bilan modellashtirish
                    va bo’yash, laminatsiya, botox, termasdan halol metodda ochartirish
                    protseduralarini bosqichma bosqich, eng sodda tarzda o’rgatib boramiz
                  </p>
                </div>
              </div>
              <div className="col-4">
                <div className="circle">
                  <img src="./images/about.JPG" />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="col-4">
                <div className="circle">
                  <img src="./images/about.JPG" />
                </div>
              </div>
              <div className="col-5">
                <div>
                  <h3>Lamimaykerlik sohasida ishlashni xohlaysizmi? </h3>
                  <p>
                    Bu kursni muvaffaqiyatli yakunlab, ushbu sohada ishlashingiz va yaxshi daromadga
                    ega bo’lishingiz ham mumkin. Biz sizlarga qoshlarni xina bilan modellashtirish
                    va bo’yash, laminatsiya, botox, termasdan halol metodda ochartirish
                    protseduralarini bosqichma bosqich, eng sodda tarzda o’rgatib boramiz
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
