import React from 'react';

export default function Information() {
  return (
    <div id="Information">
      <div className="line"></div>
      <div className="container">
        <div className="row">
          <div className="col-3">
            <div className="card">
              <div className="cardHeader">
                <div className="headImg">
                  <img src="./images/experience.png" />
                </div>
              </div>
              <div className="cardBody">
                <h2>Tajriba</h2>
                <p>
                  100 dan ortiq o'quvchilar va 30 dan ortiq shogirdlar chiqargan tajribali mutaxasis
                </p>
              </div>
            </div>
          </div>
          <div className="col-3">
            <div className="card">
              <div className="cardHeader">
                <div className="headImg">
                  <img src="./images/best-price.png" />
                </div>
              </div>
              <div className="cardBody">
                <h2>Qulay narx</h2>
                <p>Har qanday sharoitdagi ayol-qizlarimiz o'rgana olishlari uchun qulay narx</p>
              </div>
            </div>
          </div>
          <div className="col-3">
            <div className="card">
              <div className="cardHeader">
                <div className="headImg">
                  <img src="./images/beauty.png" />
                </div>
              </div>
              <div className="cardBody">
                <h2>Amaliyot</h2>
                <p>
                  Lamimaykerlik, browistlik, qosh arxitekturasi, qoshni termasdan halol metodda
                  formaga solish va avvalo buni o’zingizda amalda qo’llash
                </p>
              </div>
            </div>
          </div>
          <div className="col-3">
            <div className="card">
              <div className="cardHeader">
                <div className="headImg">
                  <img src="./images/woman.png" />
                </div>
              </div>
              <div className="cardBody">
                <h2>Intensiv kurslar</h2>
                <p>Platformada o'zingizga mos tarifni tanlash imkoniyati</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
