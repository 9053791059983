import React, { useEffect, useRef } from 'react';
import About from '../components/About';
import Tarif from '../components/Tarif';
import Feature from '../components/Feature';
import Header from '../components/Header';
import Information from '../components/Informaton';
import IntroVideo from '../components/IntroVideo';
import Button from '../components/Button';

export default function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const tarifRef = useRef(null);
  const handleScroll = (ref) => {
    window.scrollTo({
      top: ref.offsetTop,
      left: 0,
      behavior: 'smooth',
    });
  };
  return (
    <>
      <Header
        button={
          <button
            className="btn"
            style={{ border: 'none' }}
            onClick={() => handleScroll(tarifRef.current)}>
            Kursni boshlash
          </button>
        }
      />
      <Information />
      <About
        button={
          <button
            className="btn"
            style={{ border: 'none' }}
            onClick={() => handleScroll(tarifRef.current)}>
            Kursni boshlash
          </button>
        }
      />
      <Feature />
      <IntroVideo />
      <Tarif tarifRef={tarifRef} />
    </>
  );
}
