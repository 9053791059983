import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'appState',
  initialState: {
    authForm: null,
    buyCourse: null,
  },
  reducers: {
    showSignUp: (state) => {
      state.authForm = 'signup';
    },
    showSignIn: (state) => {
      state.authForm = 'signin';
    },
    showLogout: (state) => {
      state.authForm = 'logout';
    },
    closeAuthForm: (state) => {
      state.authForm = null;
    },
  },
});
export const { showSignIn, showSignUp, closeAuthForm, showLogout } = slice.actions;
export const selectAuthModal = (state) => state.appState.authForm;

export default slice.reducer;
