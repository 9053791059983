import React, { useState } from 'react';
import Modal from 'react-modal';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { closeAuthForm, selectAuthModal, showSignIn } from '../../redux/appStateSlice';
import { MdClear } from 'react-icons/md';
import { fetchRegister } from '../../redux/userStateSlice';
import toast, { Toaster } from 'react-hot-toast';

const customStyles = {
  content: {
    top: '18%',
    left: '37.5%',
    right: 'auto',
    bottom: 'auto',
    backgroundColor: 'white',
    width: '30%',
    maxHeight: '100vh',
    borderRadius: '16px',
    padding: ' 24px',
    paddingTop: '30px',
    paddingButtom: '30px',
    animationName: 'modalFade',
    animationDuration: '0.5s',
    animationTimingFunction: 'linear',
    overflowY: 'auto',
    marginBottom: '10px',
    zIndex: '10',
  },
};

export default function SignUp() {
  const selectModal = useSelector(selectAuthModal);
  const dispatch = useDispatch();
  const [localError, setLocalError] = useState(null);
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
      password: '',
    },
    onSubmit: async (values) => {
      const data = await dispatch(
        fetchRegister({
          phone_number: JSON.stringify(values.phoneNumber).replace(' ', ''),
          first_name: JSON.stringify(values.firstName).replaceAll('"', ''),
          last_name: JSON.stringify(values.lastName).replaceAll('"', ''),
          password: JSON.stringify(values.password).replaceAll('"', ''),
        }),
      );
      console.log('handleRegister', data);
      if (!data.payload) {
        dispatch(closeAuthForm());
        toast.error('Ro`yhatdan o`ta olmadingiz!!!');
        return setLocalError('Ro`yhatdan o`ta olmadingiz');
      }

      if ('token' in data.payload) {
        dispatch(closeAuthForm());
        toast.success(`Xush kelibsiz ${data.payload.data.full_name}!`, {
          duration: 4000,
          position: 'top-center',
          icon: '👏',
          iconTheme: {
            primary: '#000',
            secondary: '#fff',
          },
          ariaProps: {
            role: 'status',
            'aria-live': 'polite',
          },
        });
        window.localStorage.setItem('token', data.payload.token);
      }
    },
  });

  return (
    <div id="SignUp">
      <Toaster />
      <Modal
        style={customStyles}
        isOpen={selectModal === 'signup' ? true : false}
        ariaHideApp={false}>
        <div className="row" style={{ justifyContent: 'flex-end', fontWeight: 'bold' }}>
          <div style={{ cursor: 'pointer' }} onClick={() => dispatch(closeAuthForm())}>
            <MdClear />
          </div>
        </div>
        <form className="form" onSubmit={formik.handleSubmit}>
          <h2>Ro'yxatdan o'tish</h2>
          {localError !== null ? <p style={{ color: 'red' }}>{localError}</p> : null}
          <label htmlFor="firstName">Ismingiz</label>
          <input
            value={formik.values.firstName}
            onChange={formik.handleChange}
            type="text"
            id="firstName"
            required
          />

          <label htmlFor="lastName">Familiyangiz</label>
          <input
            value={formik.values.lastName}
            onChange={formik.handleChange}
            type="text"
            id="lastName"
            required
          />

          <label htmlFor="phoneNumber">Telefon raqamingiz</label>
          <input
            value={formik.values.phoneNumber}
            onChange={formik.handleChange}
            type="text"
            id="phoneNumber"
            required
          />

          {/* <label htmlFor="email">Elektron pochtangiz </label>
          <input
            value={formik.values.email}
            onChange={formik.handleChange}
            type="email"
            id="email"
            required
          /> */}

          <label htmlFor="password">Parol</label>
          <input
            type="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            id="password"
            required
          />

          <button className="button" type="submit">
            Ro'yxatdan o'tish
          </button>
          <div onClick={() => dispatch(showSignIn())} className="link">
            Akkauntingiz bormi? Kirish
          </div>
        </form>
      </Modal>
    </div>
  );
}
