import { configureStore } from '@reduxjs/toolkit';
import appStateSlice from './appStateSlice';
import { coursesReducer } from './courseStateSlice';
import { authReducer } from './userStateSlice';

export default configureStore({
  reducer: {
    appState: appStateSlice,
    auth: authReducer,
    course: coursesReducer,
  },
});
