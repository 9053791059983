import React from 'react';
import ListofLesson from './ListofLesson';
import VideoLesson from './VideoLesson';
import '../style/responsive.scss';
import TabSection from './TabSection';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchCourseID, fetchLessonByCourseID } from '../../redux/courseStateSlice';
import ReactLoading from 'react-loading';
export default function Lesson() {
  const params = useParams();
  const dispatch = useDispatch();
  const selectLessonByCourseID = useSelector((state) => state.course.courseByID);
  console.log('selectLessonByCourseID', selectLessonByCourseID.lessons);
  const isLessonByCourseIdLoading = selectLessonByCourseID.status === 'loading';
  const selectVideoLink = useSelector((state) => state.course.videoLink);
  const getLessonByCourseID = async () => {
    await dispatch(fetchLessonByCourseID(params['id']));
  };
  React.useEffect(() => {
    getLessonByCourseID();
  }, []);

  if (isLessonByCourseIdLoading) {
    return <ReactLoading className="loading" type="balls" color="#dca486" height={80} width={80} />;
  }
  return (
    <div id="Lesson">
      <div className="container">
        <div className="row">
          <div className="col-8">
            <VideoLesson lesson={selectLessonByCourseID.lessons}  />
            <TabSection lesson={selectLessonByCourseID.lessons} />
          </div>
          <div className="col-4">
            <ListofLesson Lessons={selectLessonByCourseID?.lessons} />
          </div>
        </div>
      </div>
    </div>
  );
}
