import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { FaInstagram, FaYoutube, FaTelegram } from 'react-icons/fa';

export default function Footer(props) {
  const exampleRef = useRef(null);
  return (
    <div id="Footer">
      <div className="container">
        <div className="row">
          <div className="line"></div>
        </div>
        <div className="row">
          <div className="col-3 navb">
            <div className="card">
              <div className="navbarBrand">
                <div className="brand" style={{ textAlign: 'center' }}>
                  <Link to="/">Medinah</Link>
                </div>
              </div>

              <h4>
                Medinah ayol va qizlar uchun doimiy ko'makdosh bo'lishni maqsad qilib qo'ygan
                platforma.
              </h4>

              <div className="col-6">
                <div className="links">
                  <a target="blanks" href="https://www.instagram.com/medinah_habibiya/">
                    <div className="bgLink">
                      <FaInstagram />
                    </div>
                  </a>
                  <a target="blank" href="https://t.me/medinah_guzellik">
                    <div className="bgLink">
                      <FaTelegram />
                    </div>
                  </a>
                  <Link to="/">
                    <div className="bgLink">
                      <FaYoutube />
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-3">
            <div className="card">
              <h2>Veb sayt</h2>
              <p>Kurs muallifi</p>

              <p>Kurs haqida</p>
              <p>Tariflar</p>
            </div>
          </div>
          <div className="col-3">
            <div className="card">
              <h2>Ma'lumot</h2>
              <p>
                <Link to="/privacy">Xavfsizlik siyosati</Link>
              </p>
              <p>
                <Link to="/condition">Foydalanish shartlari</Link>
              </p>
            </div>
          </div>
          <div className="col-3">
            <div className="card">
              <h2>Yordam</h2>
              <p>
                <a href="mailto:">aristakrat770@gmail.com</a>
              </p>
              <p>
                <a href="tel:+998994468028">tel: +998(99) 446-80-28</a>
              </p>
            </div>
          </div>
        </div>
        <div className="reserved">
          <div className="row">2022 © theamirbek. Barcha huquqlar himoyalangan.</div>
        </div>
      </div>
    </div>
  );
}
