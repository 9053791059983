import { Routes, Route } from 'react-router-dom';
import SignIn from './components/auth/SignIn';
import SignUp from './components/auth/SignUp';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import './components/style/responsive.scss';
import './components/style/main.scss';
import 'react-tabs/style/react-tabs.css';
import Course from './pages/Course';
import Home from './pages/Home';
import Privacy from './pages/Privacy';
import Condition from './pages/Condition';

import React from 'react';
import Logout from './components/auth/Logout';
import PriceModal from './components/PriceModal';
import Courseoverview from './pages/Courseoverview';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser, selectUser } from './redux/userStateSlice';

function App() {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchUser());
  }, []);

  return (
    <div>
      <div className="wrapper">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/course/:id" element={<Course />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/condition" element={<Condition />} />
          <Route path="/courseoverview/" element={<Courseoverview />} />
        </Routes>
      </div>
      <Footer />
      <SignIn />
      <SignUp />
      <Logout />
      <PriceModal />
    </div>
  );
}

export default App;
