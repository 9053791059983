import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { fetchLessonByCourseID } from '../../redux/courseStateSlice';
import DefinationLesson from './DefinationLesson';
import ListofLesson from './ListofLesson';

export default function TabSection(props) {
  const [tabIndex, setTabIndex] = useState(0);
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 920;

  useEffect(() => {
    window.addEventListener('resize', () => setWidth(window.innerWidth));
  }, []);

  return (
    <>
      <Tabs className="tabs" selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
        <TabList>
          {width < breakpoint ? <Tab>Darslar ro'yxati</Tab> : null}
          <Tab>Dars haqida</Tab>
        </TabList>

        {width < breakpoint ? (
          <TabPanel>
            <ListofLesson Lessons={props.lesson} />
          </TabPanel>
        ) : null}

        <TabPanel>
          <DefinationLesson lessons={props.lesson} />
        </TabPanel>
      </Tabs>
    </>
  );
}
