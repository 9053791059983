import axios from 'axios';

import instance from './axios';

export const signIn = async (body) => {
  return await instance.post('user/login', body);
};

export const signUp = async (body) => {
  return await instance.post('user/register', body);
};
