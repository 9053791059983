import React from 'react';
import Info from './Info';
export default function Content() {
  return (
    <div className="content">
      <div className="row diniy">
        <div className="col-5">
          <p>
            <h3>
              Qosh va kiprik laminatsiyasi joiz yoki joizmasligi haqida quyidagi videoda bilib
              olishingiz mumkin!
            </h3>
            Laminatsiya maxsulotlari tarkibida najosatlar bo’lmasa va u suv o’tgazsa joizdir deb
            aytilgan! Manba: Ustozamiz Odinaxon Muxammad Sodiqning @muslimaatuz kanallaridan olindi!
            To’g’ri hamma maxsulotni ham tarkibi barchaga ma’lum emas! Lekin halol tarkibli
            maxsulotlar ham mavjud! Man sizlarga bergan va ishlatayotgan maxsulotlarim Halol
            maxsulotlar. Ular haqida faqat kursim o’quvchilarigina bilishadi. Chunki halol tarkibli
            maxsulotlarni faqat o’zimni shaxsiy izlanishlarim orqali topganman! Va bu mani
            mexnatimga kiradi! Shundoq ommaga e’lon qilomiman!
          </p>
        </div>
        <div className="col-6">
          <video width="100%" controls controlsList="nodownload">
            <source style={{ backgroundColor: '#000' }} src="./videos/odinah.MP4" type="video/mp4" />
          </video>
        </div>
      </div>
      <h1>Mahsulotlar bilan tanishish bo'limi:</h1>
      <div className="row volume">
        <div className="col-4">
          <img width="100%" src="./imgContent/volume.jpg" />
        </div>
        <div className="col-7">
          <p>
            <p>1-sostav VOLUME LIFT qoshni laminatsiyasi uchun!</p>
            <p>2-sostav VOLUME FIXER qosh laminatsiyasini ikkinchi bosqichi uchun!</p>
          </p>
        </div>
      </div>
      <div className="row botox">
        <div className="col-7">
          <p>
            BOTOX ya’ni qosh uchun Botex vitamin vazifasini bajaradi! Siyrak va to’kiluvchan
            qoshlarga qo’llash tavsiya etiladi. Qalin qoshlarga qo’llamaslik kerak. Men masalan
            botox ishlatmayman. Botox salonchi laminatsiyachilarni siri hisoblanadi. Botox surilgan
            qosh tez o’sadi va protsedurani tez tez takrorlashingizga sabab bo’ladi. Shuning uchun
            ham salonlarda laminatsiya qilingan qoshlar bir oyga yetmasdan o’z hususiyatini
            yo’qotadi! Botex maxsuloti ishlatadiganlar diqqatiga! BOTEXNI ENG OHIRIDA SHYOTKA
            YORDAMIDA QOSHLARGA SURIP CHIQAMIZ!
            <div>
              <audio controls controlsList="nodownload">
                <source src="./imgContent/audio/botox.ogg" type="audio/ogg" />
              </audio>
            </div>
          </p>
        </div>
        <div className="col-4">
          <img width="100%" src="./imgContent/botox.jpg" />
        </div>
      </div>
      <div className="row okislitel">
        <div className="col-4">
          <img width="100%" src="./imgContent/okislitel.jpg" />
        </div>
        <div className="col-4">
          <p>
            BLOND BROW maxsuloti qoshingizni termasdan osvetleniya qilishga yordam beradi! Uni 6% li
            krem okislitel bilan ishlatishingiz kerak!
            <div>
              <audio controls controlsList="nodownload">
                <source src="./imgContent/audio/okislitel.ogg" type="audio/ogg" />
              </audio>
            </div>
          </p>
        </div>
        <div className="col-4">
          <video width="100%" height="60%" controls controlsList="nodownload">
            <source src="./videos/cream.mp4" type="video/mp4" />
          </video>
        </div>
      </div>
      <div className="row xina">
        <div className="col-4">
          <img width="100%" src="./imgContent/roll.jpg" />
        </div>
        <div className="col-4">
          <img width="100%" src="./imgContent/kontur.jpg" />
        </div>
        <div className="col-4">
          <img width="100%" src="./imgContent/xina.jpg" />
        </div>
      </div>
      <div className="row kiprik">
        <div className="col-4">
          <video width="100%" height="60%" controls controlsList="nodownload">
            <source src="./videos/1234.MP4" type="video/mp4" />
          </video>
        </div>
        <div className="col-4">
          <p>
            Kiprik ekilganda ham kley ishlatilganligi sababli taxorat o’tmidi chunki kley suv
            o’tgazmidi! Laminatsiya naborlarida ham kley qo’shishadi lekin siz aslo kley bilan
            olmang. Sizga kerakmas. Kiprik laminatsiyasi kleysiz bo’midi chunki valikka kiprik
            yopishgirilip qayirtiriladi shuning uchun ham kiprik laminatsiyasi halol hisoblanmidi!
            Kiprik ekilganda ham kley ishlatilganligi sababli taxorat o’tmidi chunki kley suv
            o’tgazmidi! Laminatsiya naborlarida ham kley qo’shishadi lekin siz aslo kley bilan
            olmang. Sizga kerakmas. Kiprik laminatsiyasi kleysiz bo’midi chunki valikka kiprik
            yopishgirilip qayirtiriladi shuning uchun ham kiprik laminatsiyasi halol hisoblanmidi!
          </p>
        </div>
        <div className="col-4">
          <video width="100%" height="60%" controls controlsList="nodownload">
            <source src="./videos/123.MP4" type="video/mp4" />
          </video>
        </div>
      </div>
      <div className="row svet">
        <div className="col-4">
          <img width="100%" src="./imgContent/svet.jpg" />
        </div>
        <div className="col-7">
          <p>
            Qizla osvetleniyaga shuni olila! Parashokdan i kremidan birga bir qo’shasila qoshiqchasi
            yordamida. O’rizordegi podvalga tushishiz bilan ong qoldegi do'konda bor. Yaxshilap
            aralashtirib tayyor holatga keganda ortiqcha qoshlaga surasila!
          </p>
        </div>
      </div>
    </div>
  );
}
