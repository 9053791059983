import React from 'react';
import Modal from 'react-modal';
import { MdClear } from 'react-icons/md';
import { BsTelegram } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { closePrice, selectPriceModal } from '../redux/courseStateSlice';

const customStyles = {
  content: {
    top: '15%',
    left: '37.5%',
    right: 'auto',
    bottom: 'auto',
    backgroundColor: 'white',
    width: '30%',
    maxHeight: '100vh',
    overflowY: 'auto',
    borderRadius: '16px',
    padding: ' 24px',
    paddingTop: '30px',
    paddingButtom: '30px',
    animationName: 'modalFade',
    animationDuration: '0.5s',
    animationTimingFunction: 'linear',
  },
  '@media (max-width: 1050px)': {
    width: '100%',
  },
};

export default function PriceModal() {
  const dispath = useDispatch();
  const selectModal = useSelector(selectPriceModal);

  return (
    <div id="PriceModal">
      <Modal ariaHideApp={false} style={customStyles} isOpen={selectModal}>
        <div className="row" style={{ justifyContent: 'flex-end', fontWeight: 'bold' }}>
          <div style={{ cursor: 'pointer' }} onClick={() => dispath(closePrice())}>
            <MdClear />
          </div>
        </div>

        <div className="buysection">
          <h2>Kursdan foydalanish uchun:</h2>
          <h3>Telegram yoki Whatsup orqali bog'laning:</h3>
          <a
            className="btn"
            style={{ textAlign: 'center', marginTop: '16px' }}
            target="blank"
            href="https://t.me/medinah_habibiya">
            Telegram link <BsTelegram size="25" />
          </a>
          <h3>Yoki quyidagi telefon raqamlardan biriga qo'ng'iroq qiling:</h3>
          <h4>
            <a></a>
            +998 90 000 21 07
          </h4>
          <h4>+998 90 446 80 28</h4>

          <div></div>
        </div>
      </Modal>
    </div>
  );
}
