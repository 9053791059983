import React, { useState } from 'react';
import Modal from 'react-modal';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { closeAuthForm, selectAuthModal, showSignUp } from '../../redux/appStateSlice';
import { MdClear } from 'react-icons/md';
import { fetchAuth } from '../../redux/userStateSlice';
import toast, { Toaster } from 'react-hot-toast';

const customStyles = {
  content: {
    top: '18%',
    left: '37.5%',
    right: 'auto',
    bottom: 'auto',
    backgroundColor: 'white',
    width: '30%',
    maxHeight: '100vh',
    overflowY: 'auto',
    borderRadius: '16px',
    padding: ' 24px',
    paddingTop: '30px',
    paddingButtom: '30px',
    animationName: 'modalFade',
    animationDuration: '0.5s',
    animationTimingFunction: 'linear',
    zIndex: '10',
  },
  '@media (max-width: 1050px)': {
    width: '100%',
  },
};

export default function SignIn() {
  const selectModal = useSelector(selectAuthModal);
  const dispatch = useDispatch();
  const [localError, setLocalError] = useState(null);

  const formik = useFormik({
    initialValues: {
      phoneNumber: '',
      password: '',
    },
    onSubmit: async (values) => {
      console.log('onsub val', values);
      const data = await dispatch(
        fetchAuth({
          phone_number: values.phoneNumber,
          password: values.password,
        }),
      );

      console.log('handleSignIn', data);
      if (!data.payload) {
        toast.error('Kira olmadingiz!!!');
        setLocalError('Kira olmadingiz!!!');
      }
      if ('token' in data.payload.data) {
        dispatch(closeAuthForm());
        toast.success(`Xush kelibsiz ${data.payload.data.full_name}!`, {
          duration: 4000,
          position: 'top-center',
          icon: '👏',
          iconTheme: {
            primary: '#000',
            secondary: '#fff',
          },
          ariaProps: {
            role: 'status',
            'aria-live': 'polite',
          },
        });

        window.localStorage.setItem('token', data.payload.data.token);
        console.log('sign in token', data.payload.data.token);
      }
    },
  });

  return (
    <div id="SignIn">
      <Toaster />
      <Modal
        style={customStyles}
        isOpen={selectModal === 'signin' ? true : false}
        ariaHideApp={false}>
        <div className="row" style={{ justifyContent: 'flex-end', fontWeight: 'bold' }}>
          <div style={{ cursor: 'pointer' }} onClick={() => dispatch(closeAuthForm())}>
            <MdClear />
          </div>
        </div>
        <form className="form" onSubmit={formik.handleSubmit}>
          <h2>Kirish</h2>
          {localError !== null ? <p style={{ color: 'red' }}>{localError}</p> : null}
          <label htmlFor="phoneNumber">Telefon raqam</label>
          <input
            className="input"
            value={formik.values.phoneNumber}
            onChange={formik.handleChange}
            type="text"
            id="phoneNumber"
            placeholder="Telefon raqamingiz"
          />
          <label htmlFor="password">Parol</label>
          <input
            type="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            id="password"
            placeholder="Parol"
          />
          <p>Akkauntingiz hali yo'qmi?</p>
          <div onClick={() => dispatch(showSignUp())} className="link">
            Ro'yxatdan o'tish
          </div>
          <button type="submit">Kirish</button>
        </form>
      </Modal>
    </div>
  );
}
