import React, { useRef } from 'react';
import Button from './Button';

export default function Header(props) {
  return (
    <div id="Header">
      <div className="container">
        <div className="header">
          <div className="imgCollection">
            <div className="first">
              <img className="firstImg" src="./images/2.JPG" />
            </div>
            <div className="second">
              <img className="secondImg" src="./images/3.JPG" />
            </div>
            <div className="third">
              <img className="thirdImg" src="./images/4.JPG" />
            </div>
            <div className="fourth">
              <img className="fourthImg" src="./images/1.JPG" />
            </div>
            <div className="text">
              <h1>Medinah “Sam Sebe Browist” kursi</h1>
              <p>
                Browmaster - o’z qoshlarini va mijozni qosh arxitekturasining maxsus texnikasi
                yordamida hamda istaklarini birlashtirgan holda yuz shakliga mos keladigan qosh
                formasini yarata oladigan masterdir!
              </p>
              <Button />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
