import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectLessonID } from '../../redux/courseStateSlice';

export default function VideoLesson({lesson= []}) {
  const [first, setfirst] = useState(0)
  const selectLessonId = useSelector(selectLessonID);
  const selectVideoLink = useSelector((state) => state.course.videoLink);

  // console.log('VideoLesson selectVideo', selectVideoLink);
  

  return (
    
    <>
      {selectVideoLink ? (
        <div className="card">
          {
            lesson.map(item => (
              selectLessonId == item._id &&
              <video key={item._id} width="100%" height="500" controls>
                <source src={`https://api.medinah.uz${selectVideoLink}`} type="video/mp4" />
              </video>
            ))
          }
        </div>
      ) : (
        <div>
          <h1 style={{ color: '#000' }}>Darslikni tanlang</h1>
        </div>
      )}
    </>
  );
}
