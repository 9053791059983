import React, { useState } from 'react';
import { MdPlayCircleFilled } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { setVideoLink } from '../../redux/courseStateSlice';
import { selectUser } from '../../redux/userStateSlice';

export default function ListofLesson({ Lessons = [] }) {
  const dispatch = useDispatch();
  const profile = useSelector(selectUser);

  return (
    <>
      {Lessons?.map((item, index) => {
        return (
          <div
            className="cardLesson"
            key={item._id}
            onClick={() =>
              dispatch(
                setVideoLink({
                  videoLink: item.videos,
                  lessonID: item._id,
                }),
              )
            }>
            <h3>
              <MdPlayCircleFilled />
              <div>{item.title}</div>
            </h3>
          </div>
        );
      })}
    </>
  );
}
