import React from 'react';
import Button from './Button';

export default function About(props) {
  return (
    <div id="About">
      <div className="container">
        <div className="row">
          <div className="col-5">
            <div className="card">
              <div className="cardHeader">
                <h1>Medinah Habibiya</h1>
              </div>
              <div className="cardBody">
                <p>Browmasterlik sohasida 6 ta diplom va sertifikatlar.</p>
                <p>1 yillik tajribaga ega master trener.</p>
                <p>100 dan ortiq o'quvchilar va 30 dan ortiq shogirdlar.</p>
                <p>O'z mualliflik o'quv qo'llanmasiga ega mutaxasis.</p>
                <p>
                  Browistlik sohasini O'zbekistonda ilk bor onlayn tarzda eng tushunarli va yagona
                  kursga aylantira olgan master.
                </p>
              </div>
              <Button />
            </div>
          </div>
          <div className="col-6">
            <div className="circle">
              <img src="./images/intro.JPG" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
